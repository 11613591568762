import React from 'react'
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/styles'

import { Box } from '../form/styles/CreateModuleForm.style'
import Grid from '@material-ui/core/Grid'
import { reportStatus, segmentedTags } from '../../utils/constants'

import MaterialModal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import PaginatedAsyncTable from '../table/PaginatedAsyncTable'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'

import Button from '../button/Button'
import StyledSVG from '../shared/StyledSVG'
import backpackSVG from '../../assets/icons/backpack.svg'
import forkIconSVG from '../../assets/icons/fork.svg'
import soapIconSVG from '../../assets/icons/soap.svg'
import sleepIconSVG from '../../assets/icons/sleep.svg'
import chatBubbleSVG from '../../assets/icons/chat-bubble.svg'
import { useHistory } from 'react-router-dom'
import UserAvatar from '../shared/UserAvatar'

const useStyles = makeStyles(theme => ({
  textColor: {
    color: '#555555 !important'
  },
  title: {
    color: '#4D5E80'
  },
  subTitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400
  },
  reportStudentInfos: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  solidBorder: {
    width: '100%',
    height: '0px',
    border: '1px solid #ADB8CC'
  },
  contentSection: {
    marginTop: 20
  },
  tagName: {
    textDecoration: 'underline'
  },
  answerSection: {
    marginBottom: 35
  },
  btnStyle: {
    textAlign: 'right',
    [theme.breakpoints.down('426')]: {
      textAlign: 'center'
    },
    '& button': {
      height: 40,
      minWidth: 150,
      [theme.breakpoints.up('xl')]: {
        marginRight: '40px'
      }
    }
  },
  paper: {
    margin: 0,
    borderRadius: '8px',
    lineHeight: '20.11px !important',
    fontSize: 16,
    minWidth: 300
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  backBtn: {
    marginTop: '40px',
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    '& svg > path': {
      stroke: '#375F92'
    },
    '& h2': {
      color: '#375F92'
    }
  }
}))

const ReportStudentView = ({
  reports,
  selector,
  fieldValues,
  initialValues,
  currentReportStudent
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [schoolsRoutineTags, setSchoolsRoutineTags] = React.useState([])
  const [foodingTags, setFoodingTags] = React.useState([])
  const [healthTags, setHealthTags] = React.useState([])
  const [restTags, setRestTags] = React.useState([])
  const [communicationTags, setComunicationTags] = React.useState([])
  const [answersHistory, setAnswersHistory] = React.useState([])
  const [historyModal, setHistoryModal] = React.useState({
    open: false,
    history: null,
    current: null
  })
  const history = useHistory()
  React.useEffect(
    () => {
      currentReportStudent?.answers?.forEach(item => {
        if (item.current_answer === true) {
          if (segmentedTags.comunication.includes(item.tag.name)) {
            setComunicationTags(prev => [...prev, item])
          } else if (segmentedTags.fooding.includes(item.tag.name)) {
            setFoodingTags(prev => [...prev, item])
          } else if (segmentedTags.health.includes(item.tag.name)) {
            setHealthTags(prev => [...prev, item])
          } else if (segmentedTags.schoolsRoutine.includes(item.tag.name)) {
            setSchoolsRoutineTags(prev => [...prev, item])
          } else {
            setRestTags(prev => [...prev, item])
          }
          return true
        } else {
          setAnswersHistory(prev => [...prev, item])
          return false
        }
      })
    },
    [currentReportStudent?.answers]
  )

  // segmentd when the answers has the same tag name
  const segmentedFoodingTags = {}
  foodingTags.forEach(item => {
    const tagName = item.tag.name
    if (!segmentedFoodingTags[tagName]) {
      segmentedFoodingTags[tagName] = []
    }
    segmentedFoodingTags[tagName].push(item)
  })

  const segmentedRoutineTags = {}
  schoolsRoutineTags.forEach(item => {
    const tagName = item.tag.name
    if (!segmentedRoutineTags[tagName]) {
      segmentedRoutineTags[tagName] = []
    }
    segmentedRoutineTags[tagName].push(item)
  })

  const segmentedHealthTags = {}
  healthTags.forEach(item => {
    const tagName = item.tag.name
    if (!segmentedHealthTags[tagName]) {
      segmentedHealthTags[tagName] = []
    }
    segmentedHealthTags[tagName].push(item)
  })

  const segmentedRestTags = {}
  restTags.forEach(item => {
    const tagName = item.tag.name
    if (!segmentedRestTags[tagName]) {
      segmentedRestTags[tagName] = []
    }
    segmentedRestTags[tagName].push(item)
  })

  const segmentedComunicationTags = {}
  communicationTags.forEach(item => {
    const tagName = item.tag.name
    if (!segmentedComunicationTags[tagName]) {
      segmentedComunicationTags[tagName] = []
    }
    segmentedComunicationTags[tagName].push(item)
  })

  const handleViewsModal = open => {
    setHistoryModal(prev => {
      return { open: open || !prev.open }
    })
  }

  const RenderAnswers = ({ studentAnswer, tagType }) => {
    const UserHistory = props => {
      return (
        <>
          <p style={{ color: '#868E96' }}>
            Respondido por: {props.currentAnswer?.user?.name}
          </p>
          <p
            style={{
              color: '#868E96',
              cursor: 'pointer',
              textDecoration: 'underline'
            }}
            onClick={() => {
              setHistoryModal(prev => {
                return {
                  history: props.history.sort((a, b) => {
                    if (a.created_at < b.created_at) {
                      return 1
                    } else if (a.created_at > b.created_at) {
                      return -1
                    }
                    return 0
                  }),
                  open: true,
                  current: props.currentAnswer
                }
              })
            }}
          >
            Visualizar detalhes
          </p>
        </>
      )
    }

    return (
      <div>
        {studentAnswer.map((item, index) => {
          return item?.[0].tag.tag_type === 'subjects_frequency' ? (
            <div className={classes.answerSection}>
              <h3 className={`${classes.tagName} ${classes.textColor}`}>
                {item[0]?.tag?.name}
              </h3>
              <Grid container spacing={2}>
                {item.map(answer => {
                  return (
                    <Grid
                      key={answer.id}
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      lg={3}
                      xl={3}
                    >
                      <h4 style={{ margin: 0 }}>{answer?.subject?.name}</h4>
                      <span style={{ color: '#868E96' }}>{answer.answer}</span>
                      <UserHistory
                        currentAnswer={answer}
                        history={answersHistory.filter(
                          ans =>
                            ans.tag.id === answer.tag.id &&
                            ans.subject.id === answer.subject.id
                        )}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </div>
          ) : item[0]?.tag?.tag_type === 'multiple_choice' ? (
            <div className={classes.answerSection}>
              <h3 className={`${classes.tagName} ${classes.textColor}`}>
                {item[0]?.tag?.name}
              </h3>
              <h4 className={classes.textColor} style={{ margin: 0 }}>
                {item[0]?.topic}
              </h4>
              {item[0]?.multiple_answers.map(answ => (
                <span
                  key={answ}
                  style={{ color: '#EF7B8E', marginRight: '15px' }}
                >
                  {answ}
                </span>
              ))}
              <p>Descrição:</p>
              <span style={{ color: '#868E96' }}>{item[0]?.answer}</span>
              <UserHistory
                currentAnswer={item[0]}
                history={answersHistory.filter(
                  ans => ans.topic === item[0].topic
                )}
              />
            </div>
          ) : item?.length > 1 ? (
            <div className={classes.answerSection}>
              <h3 className={`${classes.tagName} ${classes.textColor}`}>
                {item[0]?.tag?.name}
              </h3>
              <Grid container spacing={2}>
                {item.map(answer => {
                  return (
                    <Grid
                      key={answer.id}
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      lg={3}
                      xl={3}
                    >
                      <h4 style={{ margin: 0 }}>{answer.topic}</h4>
                      <span style={{ color: '#868E96' }}>{answer.answer}</span>
                      <UserHistory
                        currentAnswer={answer}
                        history={answersHistory.filter(
                          ans => ans.topic === answer.topic
                        )}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </div>
          ) : (
            <div className={classes.answerSection}>
              <h3 className={`${classes.tagName} ${classes.textColor}`}>
                {item[0]?.tag?.name}
              </h3>
              <h4 style={{ margin: 0 }}>{item[0]?.topic}</h4>
              <span style={{ color: '#868E96' }}>{item[0]?.answer}</span>
              <UserHistory
                currentAnswer={item[0]}
                history={answersHistory.filter(
                  ans => ans.topic === item[0]?.topic
                )}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const MainContentSection = ({ segmentedTags, tagType }) => {
    let variableData = { src: undefined, title: undefined }
    if (tagType === 'routine') {
      variableData = { src: backpackSVG, title: 'Rotina escolar' }
    } else if (tagType === 'fooding') {
      variableData = { src: forkIconSVG, title: 'Alimentação' }
    } else if (tagType === 'health') {
      variableData = { src: soapIconSVG, title: 'Higiene e saúde' }
    } else if (tagType === 'rest') {
      variableData = { src: sleepIconSVG, title: 'Sono e descanso' }
    } else if (tagType === 'communication') {
      variableData = { src: chatBubbleSVG, title: 'Comunicação' }
    }
    return (
      <Grid
        className={classes.contentSection}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <div className={classes.content}>
          <StyledSVG
            height={30}
            width={30}
            src={variableData.src}
            color={theme.palette.primary.main}
          />
          <h2>{variableData.title}</h2>
        </div>
        <div className={classes.solidBorder} />
        <RenderAnswers
          tagType={tagType}
          studentAnswer={Object.values(segmentedTags)}
        />
      </Grid>
    )
  }

  const statusProperties = (status, isForColor) => {
    switch (status) {
      case 'done': {
        if (isForColor) return '#00ACDB'
        break
      }
      case 'pending': {
        if (isForColor) return '#F69E9E'
        break
      }
      case 'open': {
        if (isForColor) return '#C4C4C4'
        break
      }
      case 'waiting_moderation': {
        if (isForColor) return '#F1925B'
        break
      }
      default:
        return ''
    }
  }

  const columns = [
    {
      key: 'name',
      name: 'Nome',
      render: function render (row) {
        return row?.user?.name
      }
    },
    {
      key: 'tag',
      name: 'Tag',
      render: function render (row) {
        return row?.tag?.name
      }
    },
    {
      key: 'topic',
      name: 'Tópico',
      render: function render (row) {
        return row?.topic
      }
    },
    {
      key: 'answer',
      name: 'Resposta',
      render: function render (row) {
        return row?.answer
      }
    },
    {
      key: 'date',
      name: 'Data da resposta',
      render: function render (row) {
        return moment(row?.created_at).format('DD/MM/YYYY HH:mm') || ''
      }
    }
  ]

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className={classes.title}>Visualização do diário</h1>
          <span className={`${classes.subTitle} ${classes.textColor}`}>
            Acompanhe de perto as atividades acadêmicas e o bem-estar de seus
            filhos.
          </span>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={classes.reportStudentInfos}>
            <div>
              <h3 className={classes.textColor}>Estudante</h3>
              <span>
                <UserAvatar
                  userName={currentReportStudent?.child?.name}
                  avatarUrl={currentReportStudent?.child?.avatar_url}
                />
              </span>
            </div>
            <div>
              <h3 className={classes.textColor}>Status</h3>
              <span
                style={{
                  color: statusProperties(currentReportStudent?.status, true)
                }}
              >
                {reportStatus[currentReportStudent?.status]}
              </span>
            </div>
          </div>
        </Grid>
        {schoolsRoutineTags.length > 0 && (
          <MainContentSection
            segmentedTags={segmentedRoutineTags}
            tagType={'routine'}
          />
        )}
        {foodingTags.length > 0 && (
          <MainContentSection
            segmentedTags={segmentedFoodingTags}
            tagType={'fooding'}
          />
        )}
        {healthTags.length > 0 && (
          <MainContentSection
            segmentedTags={segmentedHealthTags}
            tagType={'health'}
          />
        )}
        {restTags.length > 0 && (
          <MainContentSection
            segmentedTags={segmentedRestTags}
            tagType={'rest'}
          />
        )}
        {communicationTags.length > 0 && (
          <MainContentSection
            segmentedTags={segmentedComunicationTags}
            tagType={'communication'}
          />
        )}
        <Grid item xs={12} className={classes.btnStyle}>
          <Button
            variant='outlined'
            className={classes.backBtn}
            onClick={() => history.goBack()}
          >
            Voltar
          </Button>
        </Grid>
      </Grid>
      <MaterialModal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={historyModal.open}
        onClose={() => handleViewsModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Paper className={classes.paper}>
          <div style={{ margin: '20px' }}>
            <h3>
              Última resposta: {historyModal?.current?.user?.name},{' '}
              {moment(historyModal?.current?.created_at).format(
                'DD/MM/YYYY HH:mm'
              )}{' '}
            </h3>
            <PaginatedAsyncTable
              columns={columns}
              pageSize={10}
              data={
                historyModal?.history?.length > 0 ? historyModal?.history : []
              }
            />
          </div>
        </Paper>
      </MaterialModal>
    </Box>
  )
}

const mapStateToProps = state => {
  const { reports } = state
  return {
    userOccupations: state.auth.currentOccupation,
    currentReportStudent: reports.currentStudent
  }
}

export default connect(mapStateToProps)(ReportStudentView)
