import { createAction } from '@reduxjs/toolkit'
import * as types from './types'

export const getTags = createAction(types.GET_TAGS)
export const getTagsSuccessful = createAction(types.GET_TAGS_SUCCESSFUL)
export const getTagsFailure = createAction(types.GET_TAGS_FAILURE)

export const getTagOptions = createAction(types.GET_TAG_OPTIONS)
export const getTagOptionsSuccessful = createAction(
  types.GET_TAG_OPTIONS_SUCCESSFUL
)
export const getTagOptionsFailure = createAction(types.GET_TAG_OPTIONS_FAILURE)
