import { all, call, takeLatest, put } from 'redux-saga/effects'
import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'
import { formatNames } from '../../utils/formUtils'
import { showAlertMessage } from '../alert/actions'
import * as actions from './actions'
import * as services from './services'
import { occupationsIds, errors } from '../../utils/constants'
import {
  removeTeacherSubjects,
  removeClassroomTeachers
} from '../teachers/actions'

export function * fetchClassroomsRequest ({ payload }) {
  try {
    const response = yield call(services.fetchClassrooms, payload)
    yield put(actions.getClassroomsSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getClassroomsFailure(error))
  }
}

export function * fetchClassroomByIdRequest ({ payload }) {
  try {
    const response = yield call(services.fetchClassroomById, payload)
    yield put(actions.getClassroomByIdSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getClassroomByIdFailure(error))
  }
}

export function * fetchClassroomRecordsRequest ({ payload }) {
  try {
    const response = yield call(services.fetchClassroomsRecords, payload)
    yield put(actions.getClassroomRecordsSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getClassroomRecordsFailure(error))
  }
}

export function * createClassroomRequest ({ payload }) {
  try {
    const response = yield call(services.createClassroom, payload)
    if (payload.teacher_ids && payload.teacher_ids.length > 0) {
      yield all(
        payload.teacher_ids.map(teacherId => {
          return call(services.createClassroomTeacher, {
            classroom_id: response.id,
            user_id: teacherId
          })
        })
      )
    }
    yield put(actions.createClassroomSuccessful(response))
    yield put(
      showAlertMessage({
        message: 'Turma criada com sucesso.',
        severity: 'success'
      })
    )
  } catch (error) {
    const errorMessage = `Falha ao criar turma. ${
      error?.name?.title ? `${error?.name?.title}.` : ''
    }`
    yield put(actions.createClassroomFailure(error))
    yield put(showAlertMessage({ message: errorMessage, severity: 'error' }))
  }
}

export function * editClassroomRequest ({ payload }) {
  try {
    const response = yield call(services.editClassroom, payload)
    if (payload.teacher_ids && payload.teacher_ids.length > 0) {
      yield all(
        payload.teacher_ids.map(teacherId => {
          return call(services.createClassroomTeacher, {
            classroom_id: payload.id,
            user_id: teacherId
          })
        })
      )
    }
    yield put(actions.editClassroomSuccessful(response))
    yield put(
      showAlertMessage({
        message: 'Turma modificada com sucesso.',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.editClassroomFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao modificar a turma.',
        severity: 'error'
      })
    )
  }
}

export function * fetchClassroomsChildrenRequest ({ payload }) {
  try {
    const response = yield call(services.fetchChild, payload)
    yield put(actions.getClassroomChildrenSuccessful(response.data))
  } catch (error) {
    console.error(error)
    yield put(actions.getClassroomChildrenFailure(error))
  }
}

export function * createClassroomTeacherRequest ({ payload }) {
  try {
    const { timesheetValues, userToEdit } = payload
    delete payload.timesheetValues
    delete payload.userToEdit
    const newValues = { ...payload }
    yield delete newValues.school_id

    // eslint-disable-next-line camelcase
    const { email, image, ...rest } = newValues
    let response
    if (userToEdit) {
      // eslint-disable-next-line camelcase
      const { subjects, classroom_teachers_attributes } = rest
      response = yield call(services.editUser, {
        id: userToEdit.id,
        data: {
          taxpayer_number: userToEdit.taxpayer_number || rest.taxpayer_number,
          email: userToEdit.email,
          subjects,
          classroom_teachers_attributes
        }
      })
    } else {
      response = yield call(services.createUser, {
        email: email.toLowerCase(),
        ...rest
      })
    }
    if (image === 'delete') {
      yield call(services.deleteUserAvatar, {
        id: response.data.id
      })
    } else if (image && image instanceof Blob) {
      yield call(addAvatar, {
        id: response.data.id,
        avatar: image
      })
    }
    yield put(actions.createClassroomTeacherSuccessful(response))

    // if there is no classroom, send school_id
    if (
      payload.classroom_teachers_attributes.length === 0 &&
      payload.subjects.length === 0
    ) {
      yield call(services.createSchoolTeacher, {
        user_id: response.data.id,
        school_id: payload.school_id
      })
    }
    yield call(services.createTimesheet, {
      user_id: response.data.id,
      school_id: payload.school_id,
      ...timesheetValues
    })

    yield put(
      showAlertMessage({
        message: 'Docente adicionado com sucesso.',
        severity: 'success'
      })
    )
  } catch (error) {
    const messageError = `Falha ao adicionar docente.
      ${
        errors[error?.response?.data?.errors?.[0]?.id]
          ? errors[error?.response?.data?.errors?.[0]?.id]
          : ''
      }`
    yield put(actions.createClassroomTeacherFailure(error))
    yield put(
      showAlertMessage({
        message: messageError,
        severity: 'error'
      })
    )
  }
}

export function * editClassroomTeacherRequest ({ payload }) {
  const {
    name,
    phone,
    email,
    address,
    // eslint-disable-next-line camelcase
    contact_from_school,
    // eslint-disable-next-line camelcase
    classroom_teachers_attributes,
    // eslint-disable-next-line camelcase
    taxpayer_number,
    subjects,
    timesheetValues,
    image,
    removeTeacherClassroom,
    removeTeacherSubject
  } = payload.data
  try {
    const response = yield call(services.editUser, {
      id: payload.id,
      data: {
        name,
        phone,
        email: email.toLowerCase(),
        address,
        contact_from_school,
        taxpayer_number,
        subjects,
        classroom_teachers_attributes: classroom_teachers_attributes
      }
    })

    removeTeacherSubject &&
      removeTeacherSubject.length > 0 &&
      (yield put(removeTeacherSubjects({ ids: removeTeacherSubject })))

    removeTeacherClassroom &&
      removeTeacherClassroom.length > 0 &&
      (yield put(removeClassroomTeachers({ ids: removeTeacherClassroom })))

    if (timesheetValues?.id) {
      yield call(services.editTimesheet, {
        id: timesheetValues.id,
        data: { ...timesheetValues }
      })
    } else {
      if (timesheetValues) {
        yield call(services.createTimesheet, {
          user_id: response.data.id,
          school_id: payload.data.school_id,
          ...timesheetValues
        })
      }
    }
    if (image === 'delete') {
      yield call(services.deleteUserAvatar, {
        id: response.data.id
      })
    } else if (image && image instanceof Blob) {
      yield call(addAvatar, {
        id: response.data.id,
        avatar: image
      })
    }

    yield put(actions.editClassroomTeacherSuccessful(response))
    yield put(
      showAlertMessage({
        message: 'Docente atualizado com sucesso.',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.editClassroomTeacherFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao atualizar docente.',
        severity: 'error'
      })
    )
  }
}

export function * addAvatar (payload) {
  try {
    if (payload?.avatar instanceof Blob) {
      const fd = new FormData()
      fd.append('data[attributes][avatar]', payload.avatar)
      fd.append('data[id]', payload.id)
      fd.append('data[type]', 'users')
      yield call(services.editUserFormData, {
        id: payload.id,
        fd
      })
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}

function * editParentChild ({ parent, childId }) {
  try {
    const childParentAlreadyRegister = yield call(services.getChildParents, {
      childId,
      parentId: parent.id
    })
    const childParentsAttributes = childParentAlreadyRegister[0]
      ? {
          child_parents_attributes: [
            {
              id: childParentAlreadyRegister[0].id,
              relationship_type: parent.child_parent?.relationship_type,
              description: parent.child_parent?.description
            }
          ]
        }
      : {
          child_parents_attributes: [
            {
              child_id: childId,
              relationship_type: parent.child_parent?.relationship_type,
              description: parent.child_parent?.description
            }
          ]
        }
    if (parent?.avatar === 'delete') {
      yield call(services.deleteUserAvatar, {
        id: parent.id
      })
    } else if (parent?.avatar && parent?.avatar instanceof File) {
      yield call(addAvatar, {
        id: parent.id,
        avatar: parent.avatar
      })
    }
    yield call(services.editUser, {
      id: parent.id,
      data: {
        name: formatNames(parent.name),
        phone: parent.phone,
        email: parent.email.toLowerCase(),
        address: parent.address,
        taxpayer_number: parent.taxpayer_number,
        ...childParentsAttributes
      }
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}

function * createParentChild ({ parent, childId }) {
  try {
    // eslint-disable-next-line camelcase
    const { child_parent, name, email, ...rest } = parent
    const response = yield call(services.createUser, {
      ...rest,
      name: formatNames(name),
      email: email.toLowerCase(),
      child_parents_attributes: [
        {
          child_id: Number(childId),
          relationship_type: child_parent?.relationship_type,
          description: child_parent?.description
        }
      ],
      user_occupations_attributes: [{ occupation_id: occupationsIds['PARENT'] }]
    })

    if (parent?.avatar === 'delete') {
      yield call(services.deleteUserAvatar, {
        id: response.data.id
      })
    } else if (parent?.avatar && parent?.avatar instanceof File) {
      yield call(addAvatar, {
        id: response.data.id,
        avatar: parent.avatar
      })
    }
  } catch (error) {
    console.error(error)
    if (error?.response?.data?.errors?.[0]?.id === 'taxpayer_number#taken') {
      error.response.data.errors[0].id = 'taxpayer_number#taken/parent'
    }
    throw error
  }
}

function * createUserChildren ({ childrenData }) {
  try {
    const responseCreateUser = yield call(services.createUser, {
      name: formatNames(childrenData.name),
      email: childrenData.email.toLowerCase(),
      user_occupations_attributes: [
        { occupation_id: occupationsIds['STUDENT'] }
      ],
      taxpayer_number: childrenData.taxpayer_number
    })
    return responseCreateUser
  } catch (error) {
    console.error(error)
    if (error?.response?.data?.errors?.[0]?.id === 'taxpayer_number#taken') {
      error.response.data.errors[0].id = 'taxpayer_number#taken/parent'
    }
    throw error
  }
}

export function * editClassroomChildrenRequest ({ payload }) {
  try {
    // childrenData.id é passado quando o usuário estiver sendo editado enquanto o childrenId é passado somente caso a busca pelo usuário, através do CPF, retorne um usuário válido
    const {
      childrenData,
      parentsData,
      userId,
      childrenId,
      parentDeleted
    } = payload
    const childId = childrenData.id || childrenId
    let responseChildren
    const fd = new FormData()
    if (childrenData.email) {
      // create child user
      if (userId) {
        const responseEditUser = yield call(services.editUser, {
          id: userId,
          data: {
            name: formatNames(childrenData.name),
            email: childrenData.email.toLowerCase(),
            taxpayer_number: childrenData.taxpayer_number,
            phone: childrenData?.phone,
            address: childrenData?.address
          }
        })
        const responseChildId = yield call(services.getUser, {
          id: responseEditUser.data.id
        })
        responseChildren = responseChildId.data?.child
      } else {
        const newUserChildren = yield call(createUserChildren, { childrenData })
        fd.append('data[attributes][user_id]', newUserChildren.data.id)
      }
    }
    if (childrenData.avatar === 'delete') {
      yield call(services.deleteChildrenAvatar, {
        id: childId
      })
    } else {
      childrenData.avatar &&
        fd.append('data[attributes][avatar]', childrenData.avatar)
    }
    childrenData.birthdate &&
      fd.append('data[attributes][birthdate]', childrenData.birthdate)
    fd.append('data[attributes][name]', formatNames(childrenData.name))
    fd.append('data[attributes][taxpayer_number]', childrenData.taxpayer_number)
    fd.append('data[type]', 'children')
    fd.append('data[id]', childId)
    responseChildren = yield call(services.editChildren, {
      id: childId,
      fd
    })
    const classroomChildrenAlreadyExist = yield call(
      services.fetchClassroomChild,
      {
        childId: childrenId,
        classroomId: childrenData.classroom_id
      }
    )
    !classroomChildrenAlreadyExist.data.length > 0
      ? yield call(services.createClassroomChildren, {
          child_id: childrenId,
          classroom_id: childrenData.classroom_id
        })
      : undefined

    if (parentDeleted?.length > 0) {
      yield all(
        parentDeleted.map(parent => {
          return call(services.deleteChildParent, {
            id: parent.id
          })
        })
      )
    }
    if (childrenData.childIsParent && parentsData.length > 0) {
      yield all(
        parentsData.map(parent => {
          if (parent.child_parent.id) {
            return call(services.deleteChildParent, {
              id: parent.child_parent.id
            })
          }
        })
      )
    } else {
      yield all(
        parentsData?.length > 0 &&
          parentsData?.map(parent => {
            const existingParent = parent.id
            return existingParent
              ? call(editParentChild, {
                  parent,
                  childId:
                    childrenData.id ||
                    responseChildren.data?.id ||
                    responseChildren.id
                })
              : call(createParentChild, {
                  parent,
                  childId:
                    childrenData.id ||
                    responseChildren.data?.id ||
                    responseChildren.id
                })
          })
      )
    }
    yield put(
      actions.editClassroomChildrenSuccessful(responseChildren.data.attributes)
    )
    yield put(
      showAlertMessage({
        message: 'Aluno(a) atualizado com sucesso.',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.editClassroomChildrenFailure(error))
    yield put(
      showAlertMessage({
        message: `Falha ao atualizar os dados. ${
          errors?.[error?.response?.data?.errors?.[0]?.id]
            ? errors?.[error?.response?.data?.errors?.[0]?.id]
            : ''
        }`,
        severity: 'error'
      })
    )
  }
}

export function * fetchClassroomTeacherByIdRequest ({ payload }) {
  try {
    const response = yield call(services.fetchClassroomTeacher, payload)
    yield put(actions.getClassroomTeacherByIdSuccessful(response.data))
  } catch (error) {
    console.error(error)
    yield put(actions.getClassroomTeacherByIdFailure(error))
  }
}

export function * removeClassroomChildRequest ({ payload }) {
  try {
    const response = yield call(services.fetchClassroomChild, {
      childId: payload.childId,
      classroomId: payload.classroomId
    })
    const classroomsChildrenIds = response.data
    yield all(
      classroomsChildrenIds
        .filter(item => +item.classroom.school_id === +payload.schoolId)
        .map(item => {
          return call(services.deleteClassroomChild, item.id)
        })
    )
    yield put(actions.removeClassroomChildSuccessful(payload.childId))
    yield put(
      showAlertMessage({
        message: 'Aluno(a) removido com sucesso.',
        severity: 'success'
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.removeClassroomChildFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao remover aluno(a).',
        severity: 'error'
      })
    )
  }
}

export function * createClassroomChildrenRSPARequest (action) {
  try {
    const { payload: { selectedChildrens, classroomId } } = action
    const response = yield all(
      selectedChildrens.map(item => {
        const data = {
          child_id: item,
          classroom_id: classroomId
        }
        return call(services.createClassroomChildren, data)
      })
    )
    resolvePromiseAction(action, response)
  } catch (err) {
    rejectPromiseAction(action, err)
  }
}

export function * fetchChildrenByTaxpayerRSPARequest (action) {
  try {
    const { payload: { taxpayerValue } } = action
    const response = yield call(services.fetchChildrenByTaxpayer, taxpayerValue)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * fetchClassroomByChildrenIdRSPARequest (action) {
  try {
    const { payload: { childId } } = action
    const response = yield call(services.fetchClassroomByChildId, childId)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * recoverPasswordRSPARequest (action) {
  try {
    const { payload: { data } } = action
    const response = yield call(services.recoverPassword, data)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * fetchParentByEmailRSPARequest (action) {
  try {
    const { payload: { email } } = action
    const response = yield call(services.getParentsByEmail, email)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * fetchClassroomTeacherByUserIdRSPARequest (action) {
  try {
    const { payload: { userId } } = action
    const response = yield call(services.fetchClassroomTeacherByUserId, userId)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * fetchClassroomsBySchoolIdRSPARequest (action) {
  try {
    const { payload: { schoolId, onlyCount } } = action
    let response
    if (onlyCount) {
      response = yield call(services.fetchClassroomsBySchoolIdCount, schoolId)
    } else {
      response = yield call(services.fetchClassroomsBySchoolId, schoolId)
    }
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * fetchClassroomsByGradeIdRSPARequest (action) {
  try {
    const { payload: { gradeIds, isSingleGrade } } = action
    const response = yield call(services.fetchClassroomsByGrades, {
      gradeIds,
      isSingleGrade
    })
    resolvePromiseAction(action, response)
  } catch (err) {
    rejectPromiseAction(action, err)
  }
}

export function * fetchDestinationsByClassroomRSPARequest (action) {
  try {
    const { payload: { params } } = action
    const response = yield call(services.fetchDestinationsByClassroom, params)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * fetchChildrensByClassroomRSPARequest (action) {
  try {
    const { payload: { classroom } } = action
    const response = yield call(services.fetchChildByClassroom, classroom)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * fetchClassroomChildrenByChildRSPARequest (action) {
  try {
    const { payload: { child } } = action
    const response = yield call(services.fetchClassroomChildrenByChild, child)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * editChildrenRSPARequest (action) {
  try {
    const { payload: { childId } } = action
    const response = yield call(services.removeChildrenTaxpayerNmbRSPA, childId)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * editUserRSPARequest (action) {
  try {
    const { payload: { userId } } = action
    const response = yield call(services.removeUserTaxpayerNmbRSPA, userId)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * deleteUserChildrenRSPARequest (action) {
  try {
    const { payload: { userId } } = action
    const response = yield call(services.deleteUserChildren, userId)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * createChildrenWithParentsRequest ({ payload }) {
  try {
    const fd = new FormData()
    const avatar = payload?.childrenData?.avatar
    delete payload?.childrenData?.avatar
    const data = {
      data: {
        attributes: {
          name: payload.childrenData.name,
          birthdate: payload?.childrenData?.birthdate,
          taxpayer_number: payload?.childrenData?.taxpayer_number,
          classroom_id: payload?.childrenData?.classroom_id,
          ...(payload?.childrenData?.email && {
            child_user_attributes: {
              name: payload.childrenData.name,
              taxpayer_number: payload?.childrenData?.taxpayer_number,
              email: payload.childrenData.email,
              phone: payload?.childrenData?.phone,
              address: { ...payload?.childrenData?.address }
            }
          }),
          ...(payload?.parentsData?.length > 0 && {
            parents_users: payload.parentsData.map(parent => {
              return {
                email: parent.email,
                name: parent.name,
                taxpayer_number: parent?.taxpayer_number,
                phone: parent?.phone,
                address: { ...parent?.address },
                relationship_type: parent?.child_parent?.relationship_type,
                description: parent?.child_parent?.description
              }
            })
          })
        }
      }
    }
    const response = yield call(services.createChildWithParents, data)
    const parentsOfChild = yield call(services.getParentsByChild, {
      childId: response.data.data.id
    })
    yield all(
      parentsOfChild.map(parent => {
        const currentAvatar = payload.parentsData.find(
          item => item.email === parent.email
        )?.avatar
        if (currentAvatar && currentAvatar instanceof File) {
          return call(addAvatar, {
            id: parent.id,
            avatar: currentAvatar
          })
        }
      })
    )
    avatar &&
      (fd.append('data[id]', response.data.data.id),
      fd.append('data[attributes][avatar]', avatar),
      fd.append('data[type]', 'children'),
      yield call(services.editChildren, {
        id: response.data.data.id,
        fd
      }))
    yield put(actions.createChildrenWithParentsSuccessful(response.data.data))
    yield put(
      showAlertMessage({
        message: 'Usuário(s) cadastrado(s) com sucesso!',
        severity: 'success'
      })
    )
  } catch (err) {
    const messageError = `Falha ao adicionar usuário(s).
      ${
        err?.response?.data?.id === 'taxpayer_number#taken'
          ? `O CPF "${
              err?.response?.data?.taxpayer_numbers?.[0]
            }" já está em uso`
          : ''
      }.`
    yield put(actions.createChildrenWithParentsFailure(err))
    yield put(
      showAlertMessage({
        message: messageError,
        severity: 'error'
      })
    )
    console.error(err)
  }
}

export function * exportClassroomRSPARequest (action) {
  try {
    const { payload: { id } } = action
    const response = yield call(services.exportClassroom, id)
    yield put(
      showAlertMessage({
        message: 'Dados da turma exportados com sucesso!',
        severity: 'success'
      })
    )
    resolvePromiseAction(action, response)
  } catch (error) {
    console.error(error)
    yield put(
      showAlertMessage({
        message: 'Falha ao exportar os dados da turma.',
        severity: 'error'
      })
    )
    rejectPromiseAction(action, error)
  }
}

export function * watchClassrooms () {
  yield takeLatest(
    actions.createChildrenWithParents,
    createChildrenWithParentsRequest
  )
  yield takeLatest(actions.getClassrooms, fetchClassroomsRequest)
  yield takeLatest(actions.getClassroomById, fetchClassroomByIdRequest)
  yield takeLatest(actions.getClassroomRecords, fetchClassroomRecordsRequest)
  yield takeLatest(actions.createClassroom, createClassroomRequest)
  yield takeLatest(actions.editClassroom, editClassroomRequest)
  yield takeLatest(actions.getClassroomChildren, fetchClassroomsChildrenRequest)
  yield takeLatest(actions.editClassroomChildren, editClassroomChildrenRequest)
  yield takeLatest(
    actions.getClassroomTeacherById,
    fetchClassroomTeacherByIdRequest
  )
  yield takeLatest(
    actions.createClassroomTeacher,
    createClassroomTeacherRequest
  )
  yield takeLatest(actions.editClassroomTeacher, editClassroomTeacherRequest)
  yield takeLatest(actions.removeClassroomChild, removeClassroomChildRequest)
  yield takeLatest(
    actions.createClassroomChildrenRSPA.request,
    createClassroomChildrenRSPARequest
  )
  yield takeLatest(
    actions.getChildrenByTaxpayerRSPA.request,
    fetchChildrenByTaxpayerRSPARequest
  )
  yield takeLatest(
    actions.getClassroomByChildrenIdRSPA.request,
    fetchClassroomByChildrenIdRSPARequest
  )
  yield takeLatest(
    actions.recoverPasswordRSPA.request,
    recoverPasswordRSPARequest
  )
  yield takeLatest(
    actions.getParentByEmailRSPA.request,
    fetchParentByEmailRSPARequest
  )
  yield takeLatest(
    actions.getClassroomTeacherByUserIdRSPA.request,
    fetchClassroomTeacherByUserIdRSPARequest
  )
  yield takeLatest(
    actions.getClassroomsBySchoolIdRSPA.request,
    fetchClassroomsBySchoolIdRSPARequest
  )
  yield takeLatest(
    actions.getClassroomsByGradeIdRSPA.request,
    fetchClassroomsByGradeIdRSPARequest
  )
  yield takeLatest(
    actions.getDestinationsByClassroomRSPA.request,
    fetchDestinationsByClassroomRSPARequest
  )
  yield takeLatest(
    actions.getChildrensByClassroomRSPA.request,
    fetchChildrensByClassroomRSPARequest
  )
  yield takeLatest(
    actions.getClassroomChildrenByChildRSPA.request,
    fetchClassroomChildrenByChildRSPARequest
  )
  yield takeLatest(
    actions.removeChildrenTaxpayerNmbRSPA.request,
    editChildrenRSPARequest
  )
  yield takeLatest(
    actions.removeUserTaxpayerNmbRSPA.request,
    editUserRSPARequest
  )
  yield takeLatest(
    actions.deleteUserChildrenRSPA.request,
    deleteUserChildrenRSPARequest
  )
  yield takeLatest(
    actions.exportClassroomRSPA.request,
    exportClassroomRSPARequest
  )
}

export default function * classroomsSaga () {
  yield all([watchClassrooms()])
}
